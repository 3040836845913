import styled from "styled-components"

export const GreenButton = styled.button`
  border: 0;
  line-height: 1.5;
  padding: 0.85rem 2rem;
  font-size: 1.5rem;
  text-align: center;
  text-transform: uppercase;
  text-shadow: 1px 1px 1px #000;
  border-radius: 35px;
  background-color: #8aba2b;
  font-family: all-round-gothic, sans-serif;
  font-weight: bold;
  letter-spacing: 0;
  color: #ffffff;
  cursor: pointer;

  :hover {
    background-color: #ffffff;
    color: #8aba2b;
    transition: all 0.5s;
    text-shadow: none;
  }
`

export const BlueButton = styled(GreenButton)`
  background-color: #004171;

  :hover {
    background-color: #ffffff;
    color: #004171;
    transition: all 0.5s;
    text-shadow: none;
  }

  & a {
    text-decoration: none;
    color: #ffffff;
  }
`
