import React from "react"
import ContactForm from "../components/ContactForm"
import styled from "styled-components"
import { MainBlueTitle, GreenTitle } from "../components/elements/titles"
import Container from "./utilities/wrapper"
import ImgMap from "../images/contact-map.jpg"

const ContactWrapper = styled.section`
  min-height: 85vh;
  display: flex;
  flex-flow: row wrap;
  align-content: center;
  justify-content: space-evenly;
  padding: 2rem 0;

  .y-padding-lg {
    padding: 1rem 0;
  }

  .item-1 {
    margin-right: 2rem;
    text-align: center;
  }
  .item-2 {
    margin-left: 2rem;
  }

  .flex-wrapper-row {
    display: flex;
    justify-content: space-between;

    h4 {
      color: #8aba2b;
      font-size: 1.5rem;
      padding: 0.5rem 0;
    }

    p {
      color: #0064b0;
      font-size: 1.1rem;
      font-weight: 700;
    }
  }
  @media (max-width: 1135px) {
    .flex-wrapper-row {
      flex-direction: column;
    }

    .item-2 {
      margin-left: 0rem;
      transform: scale(0.85);
    }
  }
`

const ContactUsContent = () => {
  return (
    <Container>
      <ContactWrapper>
        <div className="contact-item item-1">
          <MainBlueTitle size="3.2rem">Contact Us</MainBlueTitle>
          <GreenTitle size="2.2rem" className="y-padding-lg">
            <a href="tel:+19153077231">(915) 307-7231</a>
          </GreenTitle>
          <div className="y-padding-lg">
            
          </div>
          <div className="flex-wrapper-row">
            <div className="hours-item">
              <h4>Hours</h4>
              <p>M-F 9 a.m. to 5 p.m.</p>
            </div>
            <div className="location-item">
              
            </div>
          </div>
        </div>
        <div className="contact-item item-2">
          <ContactForm />
        </div>
      </ContactWrapper>
    </Container>
  )
}

export default ContactUsContent
