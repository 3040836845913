import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ContactUsBg from "../components/ContactUsBg"

const contact = () => {
  return (
    <Layout>
      <SEO title="Contact Us" />
      <ContactUsBg />
    </Layout>
  )
}

export default contact
