import React, { useState } from "react"
import styled from "styled-components"
import { BlueButton } from "./elements/buttons"

const StyledForm = styled.form`
  display: flex;
  align-content: center;
  margin: 0 auto;
  width: 100%;
  flex-flow: column wrap;
  justify-content: center;

  .form-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 0.5rem 0;
  }

  .message-item {
    flex-direction: column;
    align-self: flex-start;
  }

  .message-item label {
    flex-direction: column;
    align-self: flex-start;
  }

  .form-item label {
    font-family: all-round-gothic, sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 1.2rem;
    margin: 0 1rem 0 0;
    color: #0064b0;
  }

  .form-item input {
    width: 280px;
  }

  input[type="text"],
  input[type="email"],
  input[type="tel"] {
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 2px solid #d1d4d6;
    border-radius: 20px;
    height: 35px;
    margin: 0.25rem 0;
    padding: 1rem;
    font-family: "Open Sans", sans-serif;
    font-size: 1rem;
  }

  input:focus {
    border: 3px solid #0064b0;
    border-radius: 20px;
    color: #2f368e;
    background: #f0f0f0;
    outline: none;
    font-family: "Open Sans", sans-serif;
    font-size: 1rem;
    transition: all 0.2s ease-in-out;
  }

  textarea:focus {
    border: 3px solid #0064b0;
    border-radius: 20px;
    background: #f0f0f0;
    outline: none;
    font-family: "Open Sans", sans-serif;
    font-size: 0.85rem;
    transition: all 0.2s ease-in-out;
  }

  textarea {
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 2px solid #d1d4d6;
    /* border: 2px solid #0064b0; */
    border-radius: 20px;
    margin: 0.25rem 0;
    padding: 1rem;
    font-family: "Open Sans", sans-serif;
    font-size: 0.85rem;
  }

  .form-item .select-style {
    display: block;
    color: #444;
    line-height: 1.3;
    padding: 0.6rem 2rem 0.6rem 0.8rem;
    max-width: 100%;
    box-sizing: border-box;
    margin: 0;
    border: 2px solid #d1d4d6;
    border-radius: 20px;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background-color: #fff;
    background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E"),
      linear-gradient(to bottom, #ffffff 0%, #e5e5e5 100%);
    background-repeat: no-repeat, repeat;
    background-position: right 0.6em top 50%, 0 0;
    background-size: 0.75em auto, 100%;
    font-size: 0.85rem;
  }

  .select-style:focus {
    border: 3px solid #0064b0;
    border-radius: 20px;
    outline: none;
    font-family: "Open Sans", sans-serif;
  }
`

const ContactForm = () => {
  const [value, setValue] = useState({
    name: "",
    email: "",
    phone: "",
    projectType: "",
    message: "",
  })

  // Prints values to check submit
  // const printValues = e => {
  //   e.preventDefault()
  //   console.log(
  //     value.name,
  //     value.email,
  //     value.phone,
  //     value.projectType,
  //     value.message
  //   )
  // }

  // Helper function so not to have multiple onClick functions for each state
  // target.name is used with a name="state value" on the input value
  const updateField = e => {
    setValue({
      ...value,
      [e.target.name]: e.target.value,
    })
  }

  return (
    <StyledForm
      name="contact"
      method="post"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
    >
      <input type="hidden" name="form-name" value="contact" />

      <div className="form-item">
        <label for="name">Name:</label>
        <input
          id="name"
          type="text"
          placeholder="Name"
          name="name"
          value={value.name}
          onChange={updateField}
          required
        />
      </div>

      <div className="form-item">
        <label for="email">E-mail:</label>
        <input
          id="email"
          type="email"
          placeholder="Email"
          name="email"
          onChange={updateField}
          required
        />
      </div>
      <div className="form-item">
        <label for="tel">Phone:</label>
        <input
          id="tel"
          type="tel"
          placeholder="phone number"
          autocomplete="tel"
          name="phone"
          onChange={updateField}
        />
      </div>
      <div className="form-item">
        <label for="project-select">Project Type:</label>
        <select
          className="select-style"
          name="projectType"
          id="project-select"
          onChange={updateField}
          required
        >
          <option value="">Please choose an option</option>
          <option value="fleet graphics">Fleet Graphics</option>
          <option value="vehicle wraps">Vehicle Wraps</option>
          <option value="screen printing">Screen Printing</option>
          <option value="embroidery">Embroidery</option>
          <option value="banners">Banners</option>
          <option value="printing">Printing</option>
          <option value="other">Other</option>
        </select>
      </div>
      <div className="form-item message-item">
        <label for="message">Message:</label>
        <textarea
          name="message"
          id="message"
          cols="48"
          rows="5"
          onChange={updateField}
          required
        ></textarea>
      </div>

      <div className="form-item">
        <BlueButton type="submit">Submit</BlueButton>
      </div>
    </StyledForm>
  )
}

export default ContactForm
